import advanceSearchModal from './advanceSearch';
import addInvestorInvoice from './addInvestorInvoice';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import investorBillDetails from '../../leaseInvoices/investorInvoicesNew/investorInvoicesDetails';
import apInvoiceDetails from '../../../../payable/apInvoice/invoiceEntry/viewInvoiceEntry';
import submittedRequest from '../../../../request/submittedRequest';
import { required } from 'vuelidate/lib/validators';
import URL_UTILITY from '../../../../../utility/url.utility';
// import store from '../../../../../../store';
export default {
  name: 'investorInvoice',
  components: {
    advanceSearchModal,
    addInvestorInvoice,
    DatePicker,
    party,
    investorBillDetails,
    apInvoiceDetails,
    submittedRequest
  },
  watch: {
    currentPage: function() {
      this.getInvestorInvoice();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvestorInvoice();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
    // selectAllFinalBox: function() {
    //   this.checkUncheckAll();
    // }
  },
  data() {
    return {
      payload: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      unsubscribe: null,
      loader: false,
      showValueSetModal: false,
      showAdvanceSearchModal: false,
      showSubmitRequestModal: false,
      showTenantBatchModal: false,
      showHideMasterAgreement: false,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      vsetCode: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      leasePrj: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      selectAllCheckBox: false,
      selectAllFinalBox: false,
      gridData: [],
      gridFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        // {
        //   key: 'final',
        //   stickyColumn: true,
        //   variant: 'primary',
        //   class: 'stickyColumn'
        // },
        {
          key: 'schedule_status_vset_meaning',
          label: 'Schedule Status'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'lease_agreement_no',
          label: 'MLA No.'
        },
        {
          key: 'lease_prj_name',
          label: 'Lease Project'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor No'
        },
        {
          key: 'lease_prj_unit_name',
          label: 'Display Name'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'billing_period_from'
        },
        {
          key: 'billing_period_to'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'FMS SubUnit'
        },
        {
          key: 'area_uom_vset_meaning',
          label: 'Area UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'rate_uom_vset_meaning',
          label: 'Rate UOM'
        },
        {
          key: 'rate'
        },
        {
          key: 'transaction_currency',
          label: 'Currency'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'amount'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'vendor_id'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site_id'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'vendor_site_address'
        },
        {
          key: 'period_days'
        },
        {
          key: 'bill_days'
        },
        {
          key: 'act_bill_date_from'
        },
        {
          key: 'act_bill_date_to'
        },
        {
          key: 'discount_type_vset_meaning',
          label: 'Discount Type'
        },
        {
          key: 'discount_value',
          label: 'Discount'
        },
        {
          key: 'discount_reason_vset_meaning',
          label: 'Discount Reason'
        },
        {
          key: 'vendor_gstn',
          label: 'Vendor GSTN'
        },
        {
          key: 'default_tax_section',
          label: 'Default Tax Section'
        },
        {
          key: 'tax_cat_name'
        },
        {
          key: 'request_num'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'document_num',
          label: 'Document Number'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number'
        },
        {
          key: 'error_msg'
        }
      ],
      searchParams: null,
      showPartyModal: false,
      gridIndex: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showCustomerSiteModal: false,
      billingPeriodDate: null,
      showInvestorBillModal: false,
      showApInvoiceModal: false,
      billHdrId: null,
      invHdrId: null,
      rowData: null,
      taxGroupId: null,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      depVsetParam: null,
      calculateAmt: 0
    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    },
    operatingUnit: {
      text: {
        required
      }
    },
    leasePrj: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.setDefaultTaxGroup();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.processInvoice();
        }
        if (actionName === 'add') {
          this.openCloseTenantBatchModal(true);
        }
        if (actionName === 'download' && !this.showTenantBatchModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseGenerateBatch/getInvestorInvoiceList',
            'investor-invoice',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setDefaultTaxGroup() {
      const groupType = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET
      );
      groupType.then(key => {
        this.taxGroupId = key[0].tax_group_id;
      });
    },
    changeDate(date, index, column) {
      if (column === 'gl_date') {
        const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
          date,
          22,
          this.gridData[index].le_id,
          this.gridData[index].ou_id
        );
        validatePeriodDatePromise.then(isPeriodOpen => {
          if (isPeriodOpen === 'NOTDEFINED') {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = `Lease Investor Agreement: ${this.gridData[index].lease_investor_agreement_no}, Input GL Date is Not Defined`;
            const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
              this.gridData[index].invoice_date,
              22,
              this.gridData[index].le_id,
              this.gridData[index].ou_id
            );
            validatePeriodDatePromise.then(isPeriodOpen => {
              this.gridData[index].gl_date =
                isPeriodOpen === 'NOTDEFINED'
                  ? null
                  : this.gridData[index].invoice_date;
              this.updateInvestorInvoiceData(index);
            });
          } else {
            this.validateGlDate(index, date);
          }
        });
      } else {
        if (this.gridData[index].gl_date) {
          const checkGlDate = commonHelper.validateInvoiceAndPeriodDate(
            this.gridData[index].gl_date,
            date
          );
          if (checkGlDate) {
            this.gridData[index].invoice_date = date;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = `Lease Investor Agreement: ${this.gridData[index].lease_investor_agreement_no}, Please select Invoice date greater than GL date!`;
          }
        } else {
          this.gridData[index].invoice_date = date;
        }
        this.updateInvestorInvoiceData(index);
      }
    },
    getInvestorInvoice() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.searchParams) {
          this.payload = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          Object.assign(this.payload, this.searchParams);
        } else {
          this.payload = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            le_id: this.legalEntity.value,
            ou_id: this.operatingUnit.value,
            lease_prj_id: this.leasePrj.value,
            bill_status_vset: this.status.value
          };
        }
        this.loader = true;
        this.$store
          .dispatch('leaseGenerateBatch/getInvestorInvoiceList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.gridData = resp.data.data.page.map(data => {
                data.selectBox = false;
                data.final = true;
                data.discount_value = data.discount_value
                  ? data.discount_value.toFixed(2)
                  : null;
                data.amount = data.amount ? data.amount.toFixed(2) : null;
                data.rate = data.rate ? data.rate.toFixed(2) : null;
                return data;
              });
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    updateInvestorInvoiceData(index) {
      const payload = {
        leaseInvestorScheduleHdrId: this.gridData[index]
          .lease_investor_schedule_dtl_id,
        data: {
          investor_schedule_details: [
            {
              vendor_id: this.gridData[index].vendor_id, //NotNull
              vendor_site_id: this.gridData[index].vendor_site_id, //NotNull
              discount_type_vset: this.gridData[index].discount_type_vset, //NotBlank
              discount_reason_vset: this.gridData[index].discount_reason_vset,
              discount_value: this.gridData[index].discount_value, //NotNull
              gl_date: this.gridData[index].gl_date, //NotBlank
              invoice_date: this.gridData[index].invoice_date, //NotBlank
              lease_investor_schedule_dtl_id: this.gridData[index]
                .lease_investor_schedule_dtl_id, //NotNull
              tax_cat_id: this.gridData[index].tax_cat_id //NotNull
            }
          ]
        }
      };
      this.loader = true;
      this.$store
        .dispatch('leaseGenerateBatch/updateInvestorInvoiceData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    processInvoice() {
      const unProcessedInvoices = this.gridData.filter(data => data.selectBox);
      if (unProcessedInvoices && unProcessedInvoices.length > 0) {
        let allowedInvoices = [];
        // if (this.checkInvoicesLength(50, unProcessedInvoices)) {
        //   allowedInvoices = commonHelper.removeExcessRecords(
        //     50,
        //     unProcessedInvoices
        //   );
        // } else {
        allowedInvoices = unProcessedInvoices;
        // }
        const dataProcess = allowedInvoices.map(data => {
          return {
            final: data.final ? 'F' : 'D',
            id: data.lease_investor_schedule_dtl_id
          };
        });
        const payload = {
          schd_id: dataProcess
        };
        this.loader = true;
        this.$store
          .dispatch('leaseGenerateBatch/processInvestorInvoice', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    mainSearch() {
      this.searchParams = null;
      this.getInvestorInvoice();
    },
    clearSearchFilters() {
      this.legalEntity = {
        value: null,
        text: null
      };
      this.operatingUnit = {
        value: null,
        text: null
      };
      this.leasePrj.value = null;
      this.leasePrj.text = null;
      this.status.text = null;
      this.status.value = null;
      this.searchParams = null;
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.calculateAmt= 0;
    },
    advanceSearch(flag) {
      this.showAdvanceSearchModal = flag;
    },
    advanceSearchData(searchParams) {
      this.legalEntity.text = searchParams.le_name;
      this.legalEntity.value = searchParams.searchParams.le_id;
      this.operatingUnit.text = searchParams.ou_name;
      this.operatingUnit.value = searchParams.searchParams.ou_id;
      this.leasePrj.text = searchParams.lease_prj_name;
      this.leasePrj.value = searchParams.searchParams.lease_prj_id;
      this.searchParams = searchParams.searchParams;
      this.showAdvanceSearchModal = false;
      this.getInvestorInvoice();
    },
    openCloseTenantBatchModal(flag) {
      this.showTenantBatchModal = flag;
    },
    rowSelected() {},
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.gridIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = this.taxGroupId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj.text = item.value_code;
        this.leasePrj.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      } else if (this.vsetCode === 'DISCOUNT_TYPE_VSET') {
        this.gridData[this.gridIndex].discount_type_vset_meaning =
          item.value_meaning;
        this.gridData[this.gridIndex].discount_type_vset = item.value_code;
      }  else if (this.vsetCode === 'DISCOUNT_REASON_VSET') {
        this.gridData[this.gridIndex].discount_reason_vset_meaning =
          item.value_meaning;
        this.gridData[this.gridIndex].discount_reason_vset = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.gridData[this.gridIndex].tax_cat_name = item.category_name;
        this.gridData[this.gridIndex].tax_cat_id = item.tax_cat_hdr_id;
      }
      this.updateInvestorInvoiceData(this.gridIndex);
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].vendor_name = item.party_name;
      this.showPartyModal = false;
      this.getVendorIdByPartyId(item.party_id);
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.gridData[this.gridIndex].vendor_id = response.data.data;
          }
        });
    },

    showHideCustomerSiteModal(flag, index) {
      if (flag) {
        this.showCustomerSiteModal = flag;
        this.gridIndex = index;
        this.$store
          .dispatch(
            'leaseSchedule/getVendorSiteByVendorId',
            this.gridData[index].vendor_id
          )
          .then(response => {
            this.gridDataSite = response.data.data.vendor_sites;
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.showCustomerSiteModal = false;
      }
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].vendor_site_id = item.vendor_site_id;
      this.gridData[this.gridIndex].vendor_site = item.site_name;
      this.gridData[this.gridIndex].vendor_site_address = item.bill_to_address;
      this.showCustomerSiteModal = false;
    },
    checkUncheckAll() {
      this.gridData = this.gridData.map((data, index) => {
        if (data.request_num) {
          if (!/e/i.test(data.schedule_status_vset)) {
            data.selectBox = false;
          } else {
            data.selectBox = this.selectAllCheckBox;
          }
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        if (this.selectAllCheckBox) {
          this.selectBoxChecked(data.selectBox, index);
        }
        // data.final = this.selectAllFinalBox;
        // if (this.selectAllFinalBox) {
        //   this.selectFinalChecked(this.selectAllFinalBox, index);
        // }
        return data;
      });
      this.calculateAmount();
    },
    calculateAmount() {
      let sum = 0;
      this.gridData.forEach(element => {
        if (element.selectBox) {
          if (element.discount_type_vset_meaning && element.discount_value) {
            if (
              element.discount_type_vset_meaning === 'ADHOC' &&
              element.discount_value
            ) {
              sum += parseFloat(element.amount - element.discount_value);
            } else if (
              element.discount_type_vset_meaning === 'PERCENTAGE' &&
              element.discount_value
            ) {
              sum += parseFloat(
                element.amount - (element.discount_value * element.amount) / 100
              );
            }
          } else {
            sum += parseFloat(element.amount);
          }
        }
        this.calculateAmt = parseFloat(sum).toFixed(2);
      });
    },
    selectBoxChecked(flag, index) {
      if (this.gridData[index].schedule_status_vset !== 'P') {
        this.gridData[index].selectBox = flag;
        if (flag) {
          this.gridData[index].invoice_date = this.gridData[index].invoice_date
            ? this.gridData[index].invoice_date
            : this.gridData[index].billing_period_from;

          if (this.gridData[index].gl_date) {
            const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
              this.gridData[index].gl_date,
              22,
              this.gridData[index].le_id,
              this.gridData[index].ou_id
            );
            validatePeriodDatePromise.then(isPeriodOpen => {
              if (isPeriodOpen === 'NOTDEFINED') {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = `Lease Investor Agreement: ${this.gridData[index].lease_investor_agreement_no}, Input GL Date is Not Defined`;
                const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
                  this.gridData[index].invoice_date,
                  22,
                  this.gridData[index].le_id,
                  this.gridData[index].ou_id
                );
                validatePeriodDatePromise.then(isPeriodOpen => {
                  this.gridData[index].gl_date =
                    isPeriodOpen === 'NOTDEFINED'
                      ? null
                      : this.gridData[index].invoice_date;
                  this.updateInvestorInvoiceData(index);
                });
              } else {
                this.validateGlDate(index, this.gridData[index].gl_date);
              }
            });
          } else {
            const glDatePromiseResult = commonHelper.getModuleBasedPeriodDate(
              22,
              this.gridData[index].le_id,
              this.gridData[index].ou_id
            );
            glDatePromiseResult.then(defaultOpenPeriodDate => {
              this.validateGlDate(index, defaultOpenPeriodDate);
            });
          }
        }
      }
      this.calculateAmount();
    },
    // selectFinalChecked(flag, index) {
    //   if (this.gridData[index].schedule_status_vset !== 'P') {
    //     this.gridData[index].final = flag;
    //     if (flag) {
    //       this.gridData[index].invoice_date = this.gridData[index].invoice_date
    //         ? this.gridData[index].invoice_date
    //         : this.gridData[index].billing_period_from;
    //       const glDatePromiseResult = commonHelper.getModuleBasedPeriodDate(
    //         store.state.shared.moduleId,
    //         this.gridData[index].le_id,
    //         this.gridData[index].ou_id
    //       );
    //       glDatePromiseResult.then(date => {
    //         this.gridData[index].gl_date = this.gridData[index].gl_date
    //           ? this.gridData[index].gl_date
    //           : date;
    //         this.updateInvestorInvoiceData(index);
    //       });
    //     }
    //   }
    // },
    validateCheckBox(row) {
      if (row.request_num) {
        if (!/e/i.test(row.schedule_status_vset)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validateGlDate(index, defaultOpenPeriodDate) {
      const checkGlDate = commonHelper.validateInvoiceAndPeriodDate(
        defaultOpenPeriodDate,
        this.gridData[index].invoice_date
      );
      if (checkGlDate) {
        this.gridData[index].gl_date = defaultOpenPeriodDate;
      } else {
        const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
          this.gridData[index].invoice_date,
          22,
          this.gridData[index].le_id,
          this.gridData[index].ou_id
        );
        validatePeriodDatePromise.then(isPeriodOpen => {
          this.gridData[index].gl_date =
            isPeriodOpen === 'NOTDEFINED'
              ? null
              : this.gridData[index].invoice_date;
        });
      }
      this.updateInvestorInvoiceData(index);
    },
    // checkInvoicesLength(permittedLength, arr) {
    //   if (arr.length > permittedLength) {
    //     this.showAlert = true;
    //     this.isSuccess = false;
    //     this.responseMsg = 'Only 50 records processed!';
    //     return true;
    //   }
    // },
    disabledDates(date) {
      const restrictedDate = new Date(this.billingPeriodDate);
      restrictedDate.setHours(0, 0, 0, 0);

      return date < restrictedDate;
    },
    setReferenceDate(row) {
      this.billingPeriodDate = row.billing_period_from;
    },
    showHideInvestorBillModal(flag, billHdrId) {
      this.showInvestorBillModal = flag;
      this.billHdrId = billHdrId;
    },
    showHideApInvoiceModal(flag, invHdrId) {
      this.showApInvoiceModal = flag;
      this.invHdrId = invHdrId;
    },
    showHideSubmitRequestModal(flag, item) {
      this.rowData = item;
      this.showSubmitRequestModal = flag;
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === 'TAX_CATEGORY_NAME_VSET') {
        this.gridData[index].tax_cat_name = null;
        this.gridData[index].tax_cat_id = null;
      } else if (vsetCode === 'DISCOUNT_TYPE_VSET') {
        this.gridData[index].discount_type_vset_meaning = null;
        this.gridData[index].discount_type_vset = null;
      }  else if (vsetCode === 'DISCOUNT_REASON_VSET') {
        this.gridData[index].discount_reason_vset_meaning = null;
        this.gridData[index].discount_reason_vset = null;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.leasePrj.text = null;
        this.leasePrj.value = null;
      } else if (vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.status.text = null;
        this.status.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('leaseGenerateBatch/saveAdvSearchFilters', null);
    // this.$store.state.leaseGenerateBatch.leaseGenerateBatch.adv_search = null;
  }
};
